import React from 'react';

const TwitterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 8.25034C12 6.18784 13.7344 4.47222 15.7969 4.50034C16.5192 4.50868 17.2237 4.72545 17.8258 5.1246C18.4278 5.52375 18.9018 6.08828 19.1906 6.75034H22.5L19.4719 9.77847C19.2765 12.8203 17.93 15.6734 15.7061 17.758C13.4823 19.8425 10.5481 21.0018 7.50002 21.0003C4.50002 21.0003 3.75002 19.8753 3.75002 19.8753C3.75002 19.8753 6.75002 18.7503 8.25002 16.5003C8.25002 16.5003 2.25002 13.5003 3.75002 5.25034C3.75002 5.25034 7.50002 9.00034 12 9.75034V8.25034Z" stroke="url(#paint0_linear_258_1471)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
    <linearGradient id="paint0_linear_258_1471" x1="13.0046" y1="4.5" x2="13.0046" y2="21.0003" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="#C9DDFF"/>
    </linearGradient>
  </defs>
</svg>
    );
};

export default TwitterIcon;