import React from 'react';
import InDevelopment from '../Components/Stake/InDevelopment';

const Home = () => {
    const prop = true;
    return (
        <>
            <InDevelopment prop={prop}/>
        </>
    );
};

export default Home;