import React, { useRef, useState, useEffect } from "react";
// import CaretDown from "../../Icons/CaretDown";
import CopyIcon from "../../Icons/CopyIcon";
import FoxIcon from "../../Icons/FoxIcon";
import { FaAngleDown } from "react-icons/fa6";
import BondsModal from "./BondsModal";
import { AiOutlineInfoCircle } from "react-icons/ai";
import SellModal from "./SellModal";
import RebondModal from "./RebondModal";

import { Nav, Tab } from "react-bootstrap"; 
import ActiveTab from "./ActiveTab";
import EndedTab from "./EndedTab";

import toast, { Toaster } from 'react-hot-toast';

import BigNumber from 'bignumber.js'
import useInterval from "../../Hooks/useInterval";
import contracts from "../../Config/constants/contracts"
import { multicall, writeContract, waitForTransaction } from '@wagmi/core'
import { useAccount } from 'wagmi'

function toLocaleString(num, min, max) {
  const _number = isNaN(Number(num)) ? 0 : Number(num)
  return _number.toLocaleString(undefined, {minimumFractionDigits: min, maximumFractionDigits: max});
}
let allBonds = [];
let unstakedBonds = [];
const StakeHead = ({...props}) => {
  const [bondsShow, setBondsShow] = useState(false);
  const [sellShow, setSellShow] = useState(false);
  const [rebondShow, setRebondShow] = useState(false);
  const [dayIndex, setDayIndex] = useState(0);

  const [warrenPrice, setWarrenPrice] = React.useState(0);
  const [totalLiquidity, setTotalLiquidity] = React.useState(0);
  const [defaultTokenBalance, setDefaultTokenBalance] = React.useState(0);
  const [userRewards, setUserRewards] = React.useState('0.0');
  const [defaultTokenPrice, setDefaultTokenPrice] = React.useState('0.0');
  const [tokensPerETH, setTokensPerETH] = React.useState('0.0');
  const [ETHPerToken, setETHPerToken] = React.useState('0.0');
  const [referral, setReferral] = React.useState('');
  const [isApproved, setIsApproved] = React.useState(false);
  const [totalBonus, setTotalBonus] = React.useState(0);
  const [selectedBond, setSelectedBond] = React.useState(-1)
  const [totalBondsStaked, setTotalBondsStaked] = React.useState(0);

  const dayOptionClick = (index) => {
    if(index == 0) setRebondShow(true)
    if(index == 1) setSellShow(true)
  };
  const dayOptions = ["Rebond", "Sell"];

  //copy text
  const textRef = useRef(null);
  const handleCopyClick = () => {
    alert('Address copied')
    if (textRef.current) {
      const textToCopy = '0xE56043671df55dE5CDf8459710433C10324DE0aE';
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = textToCopy;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
    }
  };

  BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 18,
  })

  const userAccount = useAccount({
		onConnect() {
			getStats()
			//setConnected(true)
		},
		onDisconnect() {
			getStats()
			//setConnected(false)
		},
	})

  async function getStats() {

		const response = await fetch(`https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xE56043671df55dE5CDf8459710433C10324DE0aE`);
		const rsps = await response.json();
		
		const _defaultTokenReserves = rsps.pairs[0].liquidity.usd

		const [__defaultTokenBalance, __defaultTokenSupply, __tokensPerETH, __ETHPerToken, __allowance, __pairWarrenBalance, __UIData] = await multicall({
			contracts: [
				{
					...contracts.defaultToken,
					functionName: 'balanceOf',
					args: [userAccount.address]
				},
        {
					...contracts.defaultToken,
					functionName: 'totalSupply',
					args: []
				},
        {
					...contracts.warrenProtocol,
					functionName: 'getTokensAmount',
					args: [1e18]
				},
        {
					...contracts.warrenProtocol,
					functionName: 'getETHAmount',
					args: [1e18]
				},
        {
					...contracts.defaultToken,
					functionName: 'allowance',
					args: [userAccount.address, contracts.warrenProtocol.address]
				},
        {
					...contracts.warrenToken,
					functionName: 'balanceOf',
					args: [contracts.pair.address]
				},
        {
					...contracts.warrenProtocol,
					functionName: 'getUIData',
					args: [userAccount.address]
				},
			],
		});

		const _defaultTokenBalance = new BigNumber(__defaultTokenBalance.result).div(1e18);
		setDefaultTokenBalance(_defaultTokenBalance);
    const _defaultTokenSupply = new BigNumber(__defaultTokenSupply.result).div(1e18);
		setDefaultTokenPrice(_defaultTokenReserves / _defaultTokenSupply);
    const _tokensPerETH = new BigNumber(__tokensPerETH.result).div(1e18);
		setTokensPerETH(_tokensPerETH);
    const _ETHPerToken = new BigNumber(__ETHPerToken.result).div(1e18);
		setETHPerToken(_ETHPerToken);
    const _isApproved = __allowance.result > __defaultTokenBalance.result;
		setIsApproved(_isApproved);
    const _warrenPrice = _defaultTokenReserves / _defaultTokenSupply*_ETHPerToken;
    setWarrenPrice(_warrenPrice);
    const _pairWarrenBalance = new BigNumber(__pairWarrenBalance.result).div(1e18);
    const _totalLiquidity = _warrenPrice*_pairWarrenBalance*2;
    setTotalLiquidity(_totalLiquidity);

    const [user,
    userTokensBalance,
    userHoldBonus,
    userLiquidityBonus,
    globalLiquidityBonus,
    bondActivations,
    userReferrals] = __UIData.result

    incomeData[1].price = '+' + toLocaleString(new BigNumber(globalLiquidityBonus).div(100), 2, 2) +'%';
    incomeData[2].price = '+' + toLocaleString(new BigNumber(userHoldBonus).div(100), 2, 2) +'%';
    incomeData[3].price = '+' + toLocaleString(new BigNumber(userLiquidityBonus).div(100), 2, 2) +'%';

    const _totalBonus = new BigNumber(200).plus(globalLiquidityBonus).plus(userHoldBonus).plus(userLiquidityBonus).div(100);
    setTotalBonus(_totalBonus);

    allBonds = await getAllBonds(user.bondsNumber);
    unstakedBonds = await getUnstakedBonds(_tokensPerETH);

    const _totalBondsStaked = await getTotalBondsStaked();
    setTotalBondsStaked(_totalBondsStaked);
    bodyData[0].price = toLocaleString(new BigNumber(user.totalRebonded).div(1e18), 2, 2);
    bodyData[1].price = toLocaleString(new BigNumber(user.totalSold).div(1e18), 2, 2);
    bodyData[0].priceUsd = toLocaleString(new BigNumber(Number(user.totalRebonded)*Number(warrenPrice)).div(1e18), 2, 2);
    bodyData[1].priceUsd = toLocaleString(new BigNumber(Number(user.totalSold)*Number(warrenPrice)).div(1e18), 2, 2);
    //bodyData[2].price = toLocaleString(new BigNumber(user.totalClaimed).div(1e18), 2, 2);

    const _userTokensBalance = new BigNumber(userTokensBalance).div(1e18)
    setUserRewards(_userTokensBalance);
    getActiveStakes(_totalBonus);  
	
  }

  async function getAllBonds(bondsNumber) {
    let arr = [];
    for(let i = 0; i<bondsNumber; i++) {
      arr.push({
        ...contracts.warrenProtocol,
        functionName: 'bonds',
        args: [userAccount.address, i]
      });
    }
    const bonds = await multicall({contracts: arr});
 
    
    return bonds;
  }

  async function getUnstakedBonds(_tokensPerETH) {
    const activeBonds = allBonds
    return activeBonds.map((b, i) => {
      const profitPercent = new BigNumber(b.result[3]).div(100).plus(100);
      return {
        index: i,
        amount:  new BigNumber(b.result[0]).div(1e18).times(profitPercent).div(100).times(_tokensPerETH),
        amountStaked: b.result[5],
        created: Number(b.result[1]),
        duration:  Number(b.result[2])
      }
    }).filter((b) => {
      return Number(b.amountStaked) === 0;
    }).filter(b=>(b.created + b.duration)*1000 > Date.now())
  }

  async function getTotalBondsStaked() {
    let total = 0
    allBonds
      .filter(b=>Number(b.result[5]) !== 0)
      .filter(b=>Number(b.result[7]) <= Number(b.result[8]))
      .forEach((b)=>{
        total+=Number(new BigNumber(b.result[4]).div(1e18));
      })
    return total;
  }

  async function getActiveStakes(_totalBonus) {
    const _stakeTable = []
    allBonds
    .filter(b=>Number(b.result[5]) !== 0)
    .filter(b=>Number(b.result[7]) <= Number(b.result[8]))
    .forEach((s, i) => {
      let days = toLocaleString((Date.now()/1000 - Number(s.result[5]))/86400, 0, 0);
      let profitPerPeriod = Number(s.result[7])/1e18 + ((Number(s.result[4])/1e18)*((Date.now()/1000 - Number(s.result[6])) * (_totalBonus/86400))/100);
      _stakeTable.push({
        "id": i,
        "stake": toLocaleString((Number(s.result[4])/1e18), 2, 2)+" WARREN",
        "staked": days + " Days",
        "sort": Number(s.result[5]),
        "profitDay": [{ "img": "/images/user-icon.svg" }, { "text": toLocaleString((Number(s.result[4])/1e18)*_totalBonus/100, 2, 2) }],
        "profitPeriod": [{ "img": "/images/user-icon.svg" }, { "text": toLocaleString(profitPerPeriod, 2, 2) }],
        "collectedTime": Number(s.result[6]),
        "collectetReward": Number(s.result[7]),
      })
    })
    
    _stakeTable.sort((a, b) => a.sort - b.sort);
    stakeTable = _stakeTable;
    console.log(allBonds)
  }

	useEffect(() => {
		getStats();
  },[props.prop]);

	useInterval(async () => {
		await getStats();
	}, 7000);

  async function addToken(){
    const tokenAddress = contracts.defaultToken.address;
    const tokenSymbol = 'WPLS-DAI LP';
    const tokenDecimals = 18;
    const tokenImage = 'http://warren.finance/';
    
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });
    
      if (wasAdded) {

      } else {

      }
    } catch (error) {
      console.log(error);
    }
  }

  async function stake() {
    return toast.error('Deposits have been disabled')
    const tx = writeContract({
			...contracts.warrenProtocol,
			functionName: 'stake',
			account: userAccount.address,
			args: [unstakedBonds[selectedBond].index],
			
		})
    toast.promise(
      tx,
      {
        loading: 'Awaiting for approval...',
        success: <b>Transaction submited!</b>,
        error: <b>Error, please try again.</b>,
      }
    );
    const {hash} = await tx;
    await waitForTransaction({
      hash,
    });
    toast.success('Transaction confirmed!')
		getStats();
	}

  async function sell(amount) {
    const _amount = amount.times(1e18)
    const tx = writeContract({
			...contracts.warrenProtocol,
			functionName: 'sell',
			account: userAccount.address,
			args: [_amount],
			
		})
    toast.promise(
      tx,
      {
        loading: 'Awaiting for approval...',
        success: <b>Transaction submited!</b>,
        error: <b>Error, please try again.</b>,
      }
    );
    const {hash} = await tx;
    await waitForTransaction({
      hash,
    });
    toast.success('Transaction confirmed!')
		getStats();
	}
  
  async function rebond(amount) {
    if(amount<1) { alert('The minimum amout to rebond is 1 Warren'); return; }
    if(Number(amount) > Number(userRewards)) { alert('Insuficient Warren'); return; }
    const _amount = new BigNumber(Number(amount)).times(1e18);
    const tx = writeContract({
			...contracts.warrenProtocol,
			functionName: 'rebond',
			account: userAccount.address,
			args: [_amount],
			
		})
		toast.promise(
      tx,
      {
        loading: 'Awaiting for approval...',
        success: <b>Transaction submited!</b>,
        error: <b>Error, please try again.</b>,
      }
    );
    const {hash} = await tx;
    await waitForTransaction({
      hash,
    });
    toast.success('Transaction confirmed!')
		getStats();
	}

  async function approve() {
    return toast.error('Deposits have been disabled')
		const _value = new BigNumber(Number(defaultTokenBalance)).plus(1).times(1e18)
    const tx = writeContract({
      ...contracts.defaultToken,
      functionName: 'approve',
      account: userAccount.address,
      args: [contracts.warrenProtocol.address, _value],
    });
    
    toast.promise(
      tx,
      {
        loading: 'Awaiting for approval...',
        success: <b>Transaction submited!</b>,
        error: <b>Error, please try again.</b>,
      }
    );
    const {hash} = await tx;
    await waitForTransaction({
      hash,
    });
    toast.success('Transaction confirmed!')
		getStats();
	}

  return (
    <>
    <div className="stake-head">
      <div className="stake-title box-two-bg">
        <div className="text-32 fw-semibold">Stake WARREN</div>
        <ul>
          <li>Total Liquidity = ${toLocaleString(totalLiquidity, 2, 2)}</li>
          <li>
            <img src="/images/user-icon.svg" alt="" height={20} />1 WARREN = {toLocaleString(warrenPrice, 5, 5)} USD
          </li>
        </ul>
      </div>
      <ul className="stake-income">
        <li className="box-navy-bg">
          <div>
            <div className="text-48 fw-semibold">{toLocaleString(totalBonus, 2, 2)}%</div>
            <div className="text-16 fw-semibold">Your Daily Income</div>
          </div>
        </li>
        {incomeData.map((item) => (
          <li key={item.id} className="stake-info-list">
            <div className="box-two-bg text-32 fw-medium">{item.price}</div>
            <div className="box-two-bg text-16 fw-semibold d-flex align-items-center">
              {item.desc}{" "}
              <div
                className="dropdown stake-info"
                onMouseEnter={() =>
                  document
                    .getElementById(`dropdown-menu-${item.id}`)
                    .classList.add("show")
                }
                onMouseLeave={() =>
                  document
                    .getElementById(`dropdown-menu-${item.id}`)
                    .classList.remove("show")
                }
              >
                <button
                  className="bg-none text-white"
                  type="button"
                  id={`dropdownButton-${item.id}`}
                >
                  <AiOutlineInfoCircle />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby={`dropdownButton-${item.id}`}
                  id={`dropdown-menu-${item.id}`}
                >
                  <li>
                    <div className="text-16 fw-bold title">
                      {item.info[0].infoTitle}
                    </div>
                    <div className="text-15 fw-medium">
                      {item.info[1].infoDesc}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="stake-available">
        <div className="stake-left box-bg">
          <div className="file-box content-box">
            <div className="file-head stake-file-head">
              <div>
                <input type="text" placeholder="0.0" value={unstakedBonds[selectedBond] ? unstakedBonds[selectedBond].amount : 0}/>
                <div className="text-14 fw-bold">${unstakedBonds[selectedBond] ? toLocaleString(unstakedBonds[selectedBond].amount*warrenPrice, 2, 2) : '0.00'}</div>
              </div>
              <button
                className="text-20 bg-none text-white"
                onClick={() => setBondsShow(true)}
              >
                BONDS <FaAngleDown />
              </button>
              <BondsModal unstakedBonds={unstakedBonds} show={bondsShow} onHide={(id) => {
                setBondsShow(false)
                setSelectedBond(id)
                }} />
            </div>
            {/* <div className="file-footer">
              <p className="text-14">Bonds Available:</p>
              <p className="text-14">9,364,332.77 BONDS</p>
            </div> */}
          </div>
          <div className="stake-plase">+</div>
          <div className="file-box content-box">
            <div className="file-head">
              <div>
                <input type="number" placeholder="0.0" value={(unstakedBonds[selectedBond] ? unstakedBonds[selectedBond].amount : 0) *ETHPerToken} disabled />
                <div className="text-14 fw-bold">${toLocaleString((unstakedBonds[selectedBond] ? unstakedBonds[selectedBond].amount : 0) *ETHPerToken*defaultTokenPrice, 2, 2)}</div>
              </div>
              <div className="text-20">DAI-WPLS LP</div>
            </div>
            <div className="file-footer">
              <p className="text-14">Wallet Balance:</p>
              <p className="text-14">{toLocaleString(defaultTokenBalance, 2, 2)} DAI-WPLS LP</p>
            </div>
          </div>
          <div className="content-box">
            <div className="text-16 p-2 mt-2 text-center fw-medium opacity-5">
              1 WARREN = {toLocaleString(ETHPerToken)} DAI-WPLS LP
            </div>
          </div>
          <div className="d-flex gap-4">
            {/* <button className="btn-lg btn-white-g">Buy A Bond</button> */}
            <button className="btn-lg btn-orange-g" onClick={isApproved ? stake : approve}>{isApproved ? "Stake A Bond" : "Approve"}</button>
          </div>
        </div>
        <div className="stake-right">
          <div className="box-two-bg">
            <div className="text-20 fw-semibold text-center">
              AVAILABLE WARREN
            </div>
            <div className="available-text">
              <div className="text-32 fw-bold">{toLocaleString(userRewards, 2, 4)}</div>
              <div className="text-14 fw-bold py-1">{toLocaleString(userRewards*ETHPerToken, 2, 4)} DAI-WPLS LP</div>
              <div className="text-14 fw-bold opacity-5">${toLocaleString(userRewards*ETHPerToken*defaultTokenPrice, 2, 2)}</div>
            </div>
            {/* <div className="file-box content-box mt-3 mb-3">
              <div className="file-head">
                <div>
                  <input type="number" placeholder="0.0" />
                  <div className="text-14 fw-bold">$0.00</div>
                </div>
                <div className="text-20">
                  <img src="/images/user-icon.svg" alt="" />
                </div>
              </div>
              <div className="file-footer">
                <p className="text-14">0.00 DAI-WPLS LP</p>
                <p className="text-14">$0.00</p>
              </div>
            </div> */}
            <ul className="file-tag file-day" style={{gridTemplateColumns: 'repeat(2, 1fr)'}}>
              {dayOptions.map((option, index) => (
                <li
                  key={index}
                  className={index === dayIndex ? "active" : ""}
                  onClick={() => dayOptionClick(index)}
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
          <ul className="box-two-bg stake-PLAddress">
            <li>
              <div className="text-20 fw-semibold">LP Pair Address</div>
              <div className="copy-link">
                <p ref={textRef} className="text-15 fw-semibold">
                  0xFeA...fB938E
                </p>
                <button onClick={handleCopyClick}>
                  <CopyIcon />
                </button>
              </div>
            </li>
            <li>
              <div className="pulsex-text" style={{cursor: "pointer"}} onClick={()=>{window.open('https://bafybeihp2my6aaryllnpwvx4ufy2rghrrimeeab3dmmhckcx6pj4cdmpwe.ipfs.dweb.link/#/add/V1/PLS/0xefD766cCb38EaF1dfd701853BFCe31359239F305', '_blank')}}>
                <img src="/images/pulsex.svg" alt=""/>
                <div className="text">
                  <div className="text-20 fw-semibold">Pulsex</div>
                  <div className="text-15 fw-semibold">Go to Pulsex</div>
                </div>
              </div>
            </li>
            <li onClick={addToken} style={{cursor: "pointer"}}>
              <FoxIcon />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="stake-body">
    <ul className="stake-income">
      <li className="box-navy-bg">
        <div>
          <div className="text-15 fw-medium" style={{marginBottom: "3px"}}>Total Bonds Staked | Max income 175%</div>
          <div className="text-32 fw-semibold total-staked">
            <img src="/images/user-icon.svg" alt="" /> {toLocaleString(totalBondsStaked, 2, 2)}
          </div>
          <div className="text-16 fw-semibold" style={{opacity: 0.5}}>
            ${toLocaleString(totalBondsStaked*warrenPrice, 2, 2)}
          </div>
        </div>
      </li>
      {bodyData.map((item) => (
        <li key={item.id}>
          <div className="box-two-bg text-24 fw-medium">
            <div>
              <span>{item.price} </span>
              <div className="text-14 fw-bold opacity-5" style={{marginTop:"7px"}}>${item.priceUsd}</div>
            </div>
          </div>
          <div className="box-two-bg text-16 fw-semibold">{item.desc}</div>
        </li>
      ))}
    </ul>

    <Tab.Container id="left-tabs-example" defaultActiveKey="first" justify>
      <Nav variant="pills" className="make-tabs-nav">
        <Nav.Item>
          <Nav.Link eventKey="first">Active Stakes</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="second">Ended Stakes</Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey="first">
          <ActiveTab stakeTable={stakeTable}/>
        </Tab.Pane>
        <Tab.Pane eventKey="second">
          <EndedTab endedStakeTable={endedStakeTable}/>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
    <SellModal userRewards={userRewards} ETHPerToken={ETHPerToken} defaultTokenPrice={defaultTokenPrice} show={sellShow} 
    onHide={(e) => {
      setSellShow(false);
    }}
    onSell={(e) => {
      setSellShow(false);
      sell(e);
    }}/>
    <RebondModal userRewards={userRewards} ETHPerToken={ETHPerToken} defaultTokenPrice={defaultTokenPrice} show={rebondShow} 
    onHide={(e) => {
      setRebondShow(false);
    }}
    onSell={(e) => {
      setRebondShow(false);
      rebond(e);
    }}/>
  </div>
  <Toaster
    position="top-center"
    reverseOrder={false}
  />
  </>
  );
};

export default StakeHead;

const incomeData = [
  {
    id: 1,
    price: '+'+toLocaleString(2, 2, 2)+'%',
    desc: "Base",
    info: [
      { infoTitle: "Base Daily Staking Yield" },
      {
        infoDesc:
          " Base Daily Stakin Yield",
      },
    ],
  },
  {
    id: 2,
    price: "+0.00%",
    desc: "Liquidity Bonus",
    info: [
      { infoTitle: "Liquidity Bonus" },
      {
        infoDesc:
          " For every 4287612 DAI-WPLS LP in the liquidity pool on PulseX, the daily yield will increase by 0.1%.",
      },
    ],
  },
  {
    id: 3,
    price: "+0.00%",
    desc: "Hold Bonus",
    info: [
      { infoTitle: "Hold Bonus" },
      {
        infoDesc:
          " If you don't claim or sell tokens within 24 hours, you'll receive an additional 0.05% to your daily yield.",
      },
    ],
  },
  {
    id: 4,
    price: "+0.00%",
    desc: "Personal Bonus",
    info: [
      { infoTitle: "Personal Bonus" },
      {
        infoDesc:
          " For every 153129 DAI-WPLS LP you added to the overall protocol liquidity, you'll receive an extra 0.05%.",
      },
    ],
  },
];

const bodyData = [
  {
    id: 1,
    price: "0.00",
    priceUsd: "0.00",
    priceText: "WARREN",
    desc: "Rebonded",
  },
  {
    id: 2,
    price: "0.00",
    priceUsd: "0.00",
    priceText: "WARREN",
    desc: "Sold",
  },
  /*{
    id: 3,
    price: "234,566.23",
    priceText: "WARREN",
    desc: "Claimed",
  },*/
];

let stakeTable = []

let endedStakeTable = []