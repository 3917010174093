import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./MainNavbar.scss";
import { Profile } from "../Profile"
import { FaBarsStaggered } from "react-icons/fa6";

const MainNavbar = () => {
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);
  const toggleOffcanvas = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };

  const [isNavActive, setIsNavActive] = useState(false);

  const handleButtonClick = () => {
    setIsNavActive(!isNavActive);
  };
  return (<></>)
  return (
    <>
      <nav className="navbar navbar-expand-lg main-nav">
        <div className="container">
          <Link className="logo" to="/">
            <img src="/images/logo.svg" alt="logo" />
          </Link>
          <button
            className="navbar-toggler d-lg-none ms-auto pe-0"
            type="button"
            onClick={toggleOffcanvas}
          >
            <FaBarsStaggered />
          </button>

          <div className={`navbarOffset ${offcanvasOpen ? "show" : ""}`}>
            <div className="offset-header">
              <h5 className="offcanvas-title">
                <img src="/images/logo.svg" alt="logo" />
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={toggleOffcanvas}
              ></button>
            </div>
            <ul className="nav_list">
              <li className="nav-item">
                <NavLink to="/" className="nav-link" onClick={toggleOffcanvas}>
                  Bond
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/stake"
                  className="nav-link"
                  onClick={toggleOffcanvas}
                >
                  Stake
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/program"
                  className="nav-link"
                  onClick={toggleOffcanvas}
                >
                  Incentive Program
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/chart"
                  className="nav-link"
                  onClick={toggleOffcanvas}
                >
                  Chart
                </NavLink>
              </li>
              <NavLink
                  to="https://exchange.sparkswap.xyz/#/bridge"
                  target="_blank"
                  className="nav-link"
                >
                  Bridge
                </NavLink>
            </ul>
            <div className="nav_btn_group">
              <button className="btn-lg btn-white-border">PulseChain</button>
              <Profile mobile="false"/>
            </div>
          </div>
          <div
            className={`${offcanvasOpen ? "show offcanvas-backdrop fade" : ""}`}
            onClick={toggleOffcanvas}
          ></div>
        </div>
      </nav>
    </>
  );
};

export default MainNavbar;
