import React from "react";
import { Nav, Tab } from "react-bootstrap"; 
import ActiveTab from "./ActiveTab";
import EndedTab from "./EndedTab";

const StakeBody = () => {
  return (
    <></>
  );
};

export default StakeBody;

const incomeData = [
  {
    id: 1,
    price: "0.00",
    priceText: "WARREN",
    desc: "Rebonded",
  },
  {
    id: 2,
    price: "0.00",
    priceText: "WARREN",
    desc: "Sold",
  },
  {
    id: 3,
    price: "0.00",
    priceText: "WARREN",
    desc: "Claimed",
  },
];

