import React from "react";
import { Link } from "react-router-dom";
import TelegramIcon from "../../../Icons/TelegramIcon";
import TwitterIcon from "../../../Icons/TwitterIcon";
import YoutubeIcon from "../../../Icons/YoutubeIcon";
import VectorIcon from "../../../Icons/VectorIcon";
import './Footer.scss';

const Footer = () => {
  return (<></>)
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <div className="copyright">
              © 2023 Warren.Finance. All Rights Reserved
            </div>
            <div className="footer-social">
                <Link to="https://t.me/WarrenPortal" target="_blank"><TelegramIcon/></Link>
                <Link to="https://twitter.com/Warren_Pulse" target="_blank"><TwitterIcon/></Link>
                {/* <Link to="" target="_blank"><YoutubeIcon/></Link> */}
                <Link to="https://warren-finance.gitbook.io/whitepaper/the-summary/tldr-im-in-a-hurry" target="_blank"><VectorIcon/></Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
