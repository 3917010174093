import React from "react";
import StakeHead from "./StakeHead";
import StakeBody from "./StakeBody";
import { Link } from "react-router-dom";
import TelegramIcon from "../../Icons/TelegramIcon";
import TwitterIcon from "../../Icons/TwitterIcon";
import YoutubeIcon from "../../Icons/YoutubeIcon";
import VectorIcon from "../../Icons/VectorIcon";
import "./Stake.scss";

const StakeContent = () => {
  return (
    <div className="stake-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="stake-content" style={{alignItems: "unset", padding: "70px"}}>
              <div className="stake-img">
                <img
                  src="/images/Warren-Buffet-DeNoiseAI-low-light.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>

                  <div className="stake-title box-two-bg" style={{height: "fit-content", width: "fit-content", textAlign: "center", padding: "50px 80px 50px 80px", marginLeft: "100px", marginTop:"20%", display: "block"}}>
                    <div className="text-32 fw-semibold">L2 In Development...</div>
                    <div className="text-14 fw-bold opacity-5" style={{marginTop: "10px"}}>Stay Tunned</div>
                    <div className="footer-social" style={{marginTop: "12px"}}>
                      <Link to="https://t.me/WarrenPortal" target="_blank"  style={{marginRight: "12px"}}><TelegramIcon/></Link>
                      <Link to="https://twitter.com/Warren_Pulse" target="_blank"><TwitterIcon/></Link>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakeContent;
