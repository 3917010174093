import { useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import MainNavbar from "./Components/Global/MainNavbar/MainNavbar";
import Footer from "./Components/Global/Footer/Footer";
import Bond from "./Pages/Bond";
import Home from "./Pages/InDevelopment";
import Stake from "./Pages/Stake";
// import Program from "./Pages/Program";
// import Shareholders from "./Pages/Shareholders";
import Referral from "./Pages/Referral";
import Governance from "./Pages/Governance";

import { WagmiConfig, createConfig, configureChains, useSwitchNetwork } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
 
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { pulsechain } from 'wagmi/chains'

// 1. Get projectId
const projectId = 'b7099df79b91d3aa8f5ea58707f84443'

// 2. Create wagmiConfig
const metadata = {
  name: 'Warren.Finance',
  description: 'Warren Finance',
  url: 'https://warren-app.netlify.app/' //change
}
 
// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, publicClient, webSocketPublicClient} = configureChains(
  [pulsechain],
  [publicProvider()],
)

// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'wagmi',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId,
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
})

function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  return (
    <WagmiConfig config={config}>
      <div className="App" style={{overflow:"hidden"}}>
        <BrowserRouter>
          <Wrapper>
            <MainNavbar />
            <Routes>
              {/* <Route path="/" element={<Bond/>} /> */}
              <Route path="/" element={<Home/>} />
              {/* <Route path="/stake" element={<Stake/>} /> */}
              {/* <Route path="/program" element={<Program/>} />
              <Route path="/shareholders" element={<Shareholders/>} /> */}
              {/* <Route path="/program" element={<Referral/>} />
              <Route path="/chart" element={<Governance/>} /> */}
            </Routes>
            <Footer />
          </Wrapper>
        </BrowserRouter>
      </div>
    </WagmiConfig>
  );
}

export default App;
